/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import '/src/index.css';
import Cookies from 'js-cookie';
import * as mocks from 'mocks';
import React from 'react';
import ReactDOM from 'react-dom/client';
import useLocalStorageState from 'use-local-storage-state';
import 'web-component/Plugin';

import { PqLogoIcon } from 'assets';
import { isDevelopment, isMockingActive } from 'const';
import { allRegions, allThemes } from 'types';
import type { RegionNameType, ThemeNameType } from 'types';

if (isDevelopment) {
    console.debug('Development mode');
    console.debug('NODE_ENV', process.env.NODE_ENV);

    if (isMockingActive) {
        console.debug('Mocks enabled');
        mocks.initialize();
    }
}

export const EmbedderDevelopment = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [sessionId, setSessionId] = useLocalStorageState<string | undefined>('plugin-session-id');
    const [region, setRegion] = useLocalStorageState<RegionNameType | undefined>('plugin-region');
    const [theme, setTheme] = useLocalStorageState<ThemeNameType | undefined>('plugin-theme');
    const [analyticsDigitalProductExperience, setAnalyticsDigitalProductExperience] =
        useLocalStorageState<boolean | undefined>('plugin-analytics-digital-product-experience');
    const [analyticsProductExcellenceProgram, setAnalyticsProductExcellenceProgram] =
        useLocalStorageState<boolean | undefined>('plugin-analytics-product-excellence-program');

    const isReady =
        sessionId !== undefined &&
        region !== undefined &&
        analyticsDigitalProductExperience !== undefined &&
        analyticsProductExcellenceProgram !== undefined;

    React.useEffect(() => {
        if (!isReady) {
            setIsOpen(true);
        }
    }, []);

    React.useEffect(() => {
        if (sessionId === undefined) {
            Cookies.remove('AT', { domain: '.partquest.com', path: '/' });
            return;
        }

        Cookies.set('AT', sessionId, {
            domain: '.partquest.com',
            path: '/',
        });
    }, [sessionId]);

    return (
        <>
            <div className='relative'>
                <partquest-plugin
                    session-id={sessionId}
                    region={region}
                    theme={theme}
                    analytics-digital-product-experience={analyticsDigitalProductExperience}
                    analytics-product-excellence-program={analyticsProductExcellenceProgram}
                    className='z-0'
                />

                {isOpen && (
                    <div
                        className={`absolute bottom-16 z-[99999] ${isDevelopment ? 'left-14' : ''} m-2 w-72 rounded-8 border-2 border-solid border-gray-400 bg-gray-100 p-3`}
                    >
                        <div className='flex'>
                            <h4 className='text-h4 font-bold'>Initialize web component</h4>
                            <div
                                className='ml-auto cursor-pointer px-1'
                                onClick={() => setIsOpen(false)}
                            >
                                [X]
                            </div>
                        </div>

                        {!isReady && (
                            <p className='mt-2 text-notation font-semibold text-red-600'>
                                Session ID, region and Analytics' settings need to be set. Otherwise
                                the application will be stuck in initialization.
                            </p>
                        )}

                        <div className='mt-2 text-notation'>
                            <label className='block font-semibold'>
                                Session ID (temporarily paste an access token, it will set a
                                cookie):
                            </label>
                            <input
                                type='text'
                                className='block w-full text-notation'
                                value={sessionId ?? ''}
                                onChange={(e) => {
                                    const input = e.target.value.replace(/\s/g, '');
                                    setSessionId(input === '' ? undefined : input);
                                }}
                            />
                        </div>

                        <div className='mt-2 text-notation'>
                            <label className='block font-semibold'>Region:</label>
                            <select
                                className='block w-full text-notation'
                                value={region}
                                onChange={(e) => {
                                    setRegion(
                                        e.target.value === '-1'
                                            ? undefined
                                            : (e.target.value as RegionNameType),
                                    );
                                }}
                            >
                                <option value={-1}>---</option>
                                {allRegions.map((r) => (
                                    <option key={r} value={r}>
                                        {r}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='mt-2 text-notation'>
                            <label className='block font-semibold'>
                                Theme (siemens-horizon-light if not provided):
                            </label>
                            <select
                                className='block w-full text-notation'
                                value={theme}
                                onChange={(e) =>
                                    setTheme(
                                        e.target.value === '-1'
                                            ? undefined
                                            : (e.target.value as ThemeNameType),
                                    )
                                }
                            >
                                <option value={-1}>---</option>
                                {allThemes.map((t) => (
                                    <option key={t} value={t}>
                                        {t}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className='mt-2 text-notation'>
                            <label className='block font-semibold'>
                                Analytics (Digital Product Exp.):
                            </label>
                            <select
                                className='block w-full text-notation'
                                value={
                                    analyticsDigitalProductExperience === undefined
                                        ? -1
                                        : analyticsDigitalProductExperience.toString()
                                }
                                onChange={(e) => {
                                    setAnalyticsDigitalProductExperience(
                                        e.target.value === '-1'
                                            ? undefined
                                            : e.target.value === 'true',
                                    );
                                }}
                            >
                                <option value={-1}>---</option>
                                <option value='true'>Opt in</option>
                                <option value='false'>Opt out</option>
                            </select>
                        </div>

                        <div className='mt-2 text-notation'>
                            <label className='block font-semibold'>
                                Analytics (Product Excellence Program):
                            </label>
                            <select
                                className='block w-full text-notation'
                                value={
                                    analyticsProductExcellenceProgram === undefined
                                        ? -1
                                        : analyticsProductExcellenceProgram.toString()
                                }
                                onChange={(e) => {
                                    setAnalyticsProductExcellenceProgram(
                                        e.target.value === '-1'
                                            ? undefined
                                            : e.target.value === 'true',
                                    );
                                }}
                            >
                                <option value={-1}>---</option>
                                <option value='true'>Opt in</option>
                                <option value='false'>Opt out</option>
                            </select>
                        </div>
                    </div>
                )}

                <PqLogoIcon
                    className={`absolute bottom-0 z-[99999] ${isDevelopment ? 'left-14' : ''} m-3 cursor-pointer`}
                    width={40}
                    height={40}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </div>
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <EmbedderDevelopment />
    </React.StrictMode>,
);
